import styled from '@emotion/styled';

import { H3 } from '../../../../components/Typography/Typography.styles';

export const WishChoiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

export const WishChoiceContainer = styled.div<{ wishTypeIndex: number; chosen: boolean }>`
  margin-left: ${(props: any) => (props.wishTypeIndex > 0 ? '2.5rem' : '0px')};
  border-bottom: ${(props: any) => (props.chosen ? `0.25rem solid ${props.theme.colors.red}` : 'none')};
  text-transform: uppercase;
  padding-bottom: 1rem;
  &:hover {
    border-bottom: ${(props: any) => `0.25rem solid ${props.theme.colors.red}`};
  }
  @media (max-width: 768px) {
    margin-left: ${(props: any) => (props.wishTypeIndex > 0 ? '1.2rem' : '0px')};
  }
`;

export const WishChoiceValue = styled(H3)<{ chosen: boolean }>`
  cursor: pointer;
  color: ${(props: any) => (props.chosen ? props.theme.colors.red : '')};
`;
