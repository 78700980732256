import { useEffect, useRef, useState } from 'react';

import { isEmpty } from 'lodash';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { MuteIconWrapper, ReceiverWrapper, ReplayIconWrapper } from './Home.styles';
import { decryptString } from './utils';
import { Button } from '../../../components/Button/Button.styles';
import ContentContainer from '../../../components/ContentContainer/ContentContainer';
import MuteIcon from '../../../components/Icons/MuteIcon';
import ReplayIcon from '../../../components/Icons/ReplayIcon';
import UnMuteIcon from '../../../components/Icons/UnMuteIcon';
import { ButtonLabel, H1Cursor, H2, H4 } from '../../../components/Typography/Typography.styles';
import { RECEIVER_FLOW_ACTIVE_UNTIL } from '../../../constants/variables';
import {
  DEFAULT_VIDEO,
  receiverVideoConfiguration,
  DEFAULT_POSTER,
  receiverPosterConfiguration,
} from '../../../layout/MainLayout/utils';
import { RouteNames } from '../../../routes/constants';
import { GreetingType } from '../../Sender/CreationWizard/types';
import ReceiverMobile from '../Mobile/RecieverMobile';

const Home = () => {
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();
  const [playVideo, setPlayVideo] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>(DEFAULT_VIDEO);
  const [posterUrl, setPosterUrl] = useState<string>(DEFAULT_POSTER);

  const [canReplay, setCanReplay] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<any>(null);

  const message = searchParam.get('message');
  const headline = searchParam.get('headline');
  const sender = searchParam.get('sender');

  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobile = width <= 768;

  useEffect(() => {
    if (RECEIVER_FLOW_ACTIVE_UNTIL) {
      navigate(`/${RouteNames.UNAVAILABLE}`);

      return;
    }
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener('resize', () => {
        setWidth(window.innerWidth);
      });
    };
  }, []);

  useEffect(() => {
    if (isEmpty(videoUrl)) {
      return;
    }

    if (videoRef.current) {
      videoRef.current.style.opacity = '0';
      containerRef.current.style.opacity = '0';
    }
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.style.opacity = '1';
        videoRef.current.load();
      }
    }, 300);

    const eventHandler = () => {
      setTimeout(() => {
        containerRef.current.style.opacity = '1';
        setCanReplay(true);
      }, 50);
    };

    videoRef.current?.addEventListener('ended', eventHandler);

    return () => {
      videoRef.current?.removeEventListener('ended', eventHandler);
    };
  }, [videoUrl]);

  if (playVideo && isMobile) {
    return <ReceiverMobile />;
  }

  return (
    <>
      {playVideo && (
        <>
          <video
            playsInline
            autoPlay
            muted={isMuted}
            loop={false}
            poster={posterUrl}
            ref={videoRef}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transition: 'all 500ms',
              zIndex: 999,
            }}>
            <source src={videoUrl} type='video/mp4' />
          </video>
          {videoUrl && !canReplay && (
            <MuteIconWrapper variant='secondary' onClick={() => setIsMuted(!isMuted)}>
              {isMuted ? <MuteIcon /> : <UnMuteIcon />}
            </MuteIconWrapper>
          )}
          <ReceiverWrapper
            ref={containerRef}
            style={{
              width: '35vw',
            }}>
            <ReplayIconWrapper
              variant='secondary'
              onClick={() => {
                if (videoRef.current) {
                  containerRef.current.style.opacity = '0';
                  setTimeout(() => {
                    if (videoRef.current) {
                      videoRef.current.pause();
                      videoRef.current.currentTime = 0;
                      videoRef.current.play();
                    }
                    setCanReplay(false);
                  }, 300);
                }
              }}>
              <ReplayIcon />
            </ReplayIconWrapper>
            {searchParam.get('headline') && <H1Cursor>—</H1Cursor>}
            {searchParam.get('headline') && <H2>{decryptString(headline ?? '')}</H2>}
            <H4 style={{ marginTop: '2rem' }}>{decryptString(message ?? '')}</H4>
            <H4 style={{ marginTop: '2rem' }}>{sender}</H4>
          </ReceiverWrapper>
        </>
      )}
      <ContentContainer
        heading='Season’s Greetings '
        leadParagraph='Welcome! It’s the season for sharing good wishes, and you’ve received a message. Please click on the red button
      below, turn the sound for your device on, and enjoy your animated e-card.'>
        <Button
          variant='primary'
          expanded
          onClick={() => {
            setPlayVideo(true);
            setVideoUrl(
              receiverVideoConfiguration[(searchParam.get('greeting') as GreetingType) ?? GreetingType.HOLIDAY_GIFT],
            );
            setPosterUrl(
              receiverPosterConfiguration[(searchParam.get('greeting') as GreetingType) ?? GreetingType.HOLIDAY_GIFT],
            );
          }}
          style={{ marginTop: '2rem', marginBottom: '1rem' }}>
          <ButtonLabel>See your wishes</ButtonLabel>
        </Button>
        <div className='abbTagline'></div>
      </ContentContainer>
    </>
  );
};

export default Home;
