import { useSearchParams } from 'react-router-dom';

import { PreviewRow, PreviewWrapper, Wrapper } from './WishesPreview.styles';
import { Button, SmallButton } from '../../../../components/Button/Button.styles';
import EditIcon from '../../../../components/Icons/EditIcon';
import WishesPreviewIcon from '../../../../components/Icons/WishPreview';
import { BodyLight, ButtonLabel, H3, Label } from '../../../../components/Typography/Typography.styles';
import { REACT_FE_BASE_URI } from '../../../../constants/variables';
import { RouteNames } from '../../../../routes/constants';
import { WizardStepProps, WizardSteps } from '../types';

const WishesPreview = ({ wishConfiguration, isSubmitting }: WizardStepProps) => {
  const [, setSearchParams] = useSearchParams();

  return (
    <Wrapper>
      <PreviewWrapper>
        <PreviewRow>
          <Button
            variant='secondary'
            onClick={() => {
              sessionStorage.setItem('temp_wishconfig', JSON.stringify(wishConfiguration));
              // Fixes dual-screen position                             Most browsers      Firefox
              const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
              const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

              const width = window.innerWidth
                ? window.innerWidth
                : document.documentElement.clientWidth
                ? document.documentElement.clientWidth
                : screen.width;
              const height = window.innerHeight
                ? window.innerHeight
                : document.documentElement.clientHeight
                ? document.documentElement.clientHeight
                : screen.height;

              const systemZoom = width / window.screen.availWidth;
              const left = (width - 1200) / 2 / systemZoom + dualScreenLeft;
              const top = (height - 720) / 2 / systemZoom + dualScreenTop;
              const newWindow = window.open(
                `${REACT_FE_BASE_URI}/${RouteNames.WISH_SNEAK}`,
                'Wish Preview',
                `scrollbars=yes,width=${1200 / systemZoom},height=${720 / systemZoom},top=${top},left=${left}`,
              );

              newWindow?.focus();
            }}>
            <ButtonLabel style={{ marginRight: '0.5rem' }}>Preview your wishes</ButtonLabel>
            <WishesPreviewIcon />
          </Button>
        </PreviewRow>
      </PreviewWrapper>
      <PreviewWrapper>
        <PreviewRow>
          <H3 style={{ flexGrow: 1 }}>THEME</H3>
          <SmallButton
            variant='secondary'
            onClick={() => {
              setSearchParams({
                step: WizardSteps.WIZARD_GREETING_CHOICE.toLowerCase(),
                wishType: wishConfiguration.greetingType?.toLowerCase().replaceAll(' ', '_') ?? '',
              });
            }}
            disabled={isSubmitting}>
            <Label style={{ marginRight: '0.5rem' }}>Edit</Label>
            <EditIcon />
          </SmallButton>
        </PreviewRow>
        <BodyLight>{wishConfiguration.greetingType}</BodyLight>
      </PreviewWrapper>
      <PreviewWrapper>
        <PreviewRow>
          <H3 style={{ flexGrow: 1 }}>MESSAGE</H3>
          <SmallButton
            variant='secondary'
            onClick={() => {
              setSearchParams({ step: WizardSteps.WIZARD_PERSONALIZE_MESSAGE.toLowerCase() });
            }}
            disabled={isSubmitting}>
            <Label style={{ marginRight: '0.5rem' }}>Edit</Label>
            <EditIcon />
          </SmallButton>
        </PreviewRow>
        {wishConfiguration.wishHeadline && <BodyLight>{wishConfiguration.wishHeadline}</BodyLight>}
        <BodyLight>{wishConfiguration.wishMessage}</BodyLight>
        <BodyLight>{wishConfiguration.sender}</BodyLight>
      </PreviewWrapper>
      <PreviewWrapper>
        <PreviewRow>
          <H3 style={{ flexGrow: 1 }}>RECIPIENTS</H3>
          <SmallButton
            variant='secondary'
            onClick={() => {
              setSearchParams({ step: WizardSteps.WIZARD_RECIPIENT_ADDITION.toLowerCase() });
            }}
            disabled={isSubmitting}>
            <Label style={{ marginRight: '0.5rem' }}>Edit</Label>
            <EditIcon />
          </SmallButton>
        </PreviewRow>
        <BodyLight>{`${wishConfiguration.recipients?.length} recipient${
          (wishConfiguration.recipients?.length ?? 0) > 1 ? 's' : ''
        }`}</BodyLight>
      </PreviewWrapper>
    </Wrapper>
  );
};

export default WishesPreview;
