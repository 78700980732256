import { IconProps } from './types';

const MuteIcon = ({ fill = '#262626', width = 20, height = 20 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox='0 0 20 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.25825 0.078125L18.9216 16.7423L17.7433 17.9215L1.07909 1.25729L2.25742 0.078125H2.25825ZM3.13325 5.66646L9.99992 12.5331V16.4998H8.33325L4.16659 12.3331H2.49992C2.05789 12.3331 1.63397 12.1575 1.32141 11.845C1.00885 11.5324 0.833252 11.1085 0.833252 10.6665V7.33312C0.833252 6.8911 1.00885 6.46717 1.32141 6.15461C1.63397 5.84205 2.05789 5.66646 2.49992 5.66646H3.13325ZM17.2583 1.74146C19.6583 4.14979 19.6583 10.5165 17.2583 12.9248L16.0749 11.7415C17.8499 9.97479 17.8499 4.69146 16.0749 2.92479L17.2583 1.74146ZM14.7583 4.24146C15.523 5.08959 15.9463 6.19109 15.9463 7.33312C15.9463 8.47516 15.523 9.57666 14.7583 10.4248L13.5749 9.24146C14.0271 8.70841 14.2753 8.03212 14.2753 7.33312C14.2753 6.63412 14.0271 5.95783 13.5749 5.42479L14.7583 4.24146ZM9.99992 1.49979V5.46646L7.18325 2.64979L8.33325 1.49979H9.99992Z'
        fill={fill}
      />
    </svg>
  );
};

export default MuteIcon;
