import { RouteObject } from 'react-router-dom';

import { RouteNames } from './constants';
import ProtectedRoute from '../helpers/GuardedPage';
import MainLayout from '../layout/MainLayout/MainLayout';
import Login from '../pages/Login';
import { default as ReceiverHome } from '../pages/Receiver/Home/Home';
import CreationWizard from '../pages/Sender/CreationWizard/CreationWizard';
import { default as SenderHome } from '../pages/Sender/Home/Home';
import Unavailability from '../pages/Unavailability';
import WishSneak from '../pages/WishSneak/WishSneak';

export const routes: Array<RouteObject> = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '*',
        element: (
          <ProtectedRoute>
            <SenderHome />
          </ProtectedRoute>
        ),
      },
      {
        path: '',
        element: <SenderHome />,
      },
      {
        path: RouteNames.WISH_WIZARD,
        element: (
          <ProtectedRoute>
            <CreationWizard />
          </ProtectedRoute>
        ),
      },
      {
        path: RouteNames.WISH_PREVIEW,
        element: <ReceiverHome />,
      },
      {
        path: '',
        element: <ReceiverHome />,
      },

      {
        path: 'unavailable',
        element: <Unavailability />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: RouteNames.WISH_SNEAK,
        element: (
          <ProtectedRoute>
            <WishSneak />
          </ProtectedRoute>
        ),
      },
    ],
  },
];
