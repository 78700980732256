import styled from '@emotion/styled';

import { Body } from '../../../components/Typography/Typography.styles';

export const WizardFooter = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100vw - 6rem);
  height: 6.25rem;
  background: red;
  position: fixed;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  align-items: center;
  padding: 0rem 3rem;
  justify-items: flex-start;
  @media (max-width: 768px) {
    width: calc(-2rem + 100vw);
    padding: 0rem 1rem;
  }
`;

export const WizardStepper = styled(Body)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
`;
