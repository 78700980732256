import { useEffect, useState } from 'react';

import { MinusIcon } from '@chakra-ui/icons';
import { FormControl } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { AddButtonWrapper, HorizontalLine, RecipientRow, RecipientWrapper } from './RecipientAddition.styles';
import { Button, SmallButton, SmallIconButton } from '../../../../components/Button/Button.styles';
import { FormLabel, TextInput } from '../../../../components/FormComponents/FormComponents.styles';
import AddIcon from '../../../../components/Icons/AddIcon';
import UploadIcon from '../../../../components/Icons/UploadIcon';
import Modal from '../../../../components/Modal/Modal';
import { ButtonLabel, InputErrorMessage, Label } from '../../../../components/Typography/Typography.styles';
import { WishRecipient, WizardStepProps } from '../types';
import { isEmailDuplicate, isValidEmail } from '../utils';

const RecipientAddition = ({ wishConfiguration, setCanContinue }: WizardStepProps) => {
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [recipients, setRecipients] = useState<Array<WishRecipient>>(wishConfiguration.recipients ?? []);

  useEffect(() => {
    const allNamesValid = recipients.every(rec => !isEmpty(rec.name?.trim()));
    const allEmailsValid = recipients.every(
      (rec, index) =>
        !isEmpty(rec.email) && isValidEmail(rec.email ?? '') && !isEmailDuplicate(rec.email ?? '', recipients, index),
    );
    setCanContinue(allNamesValid && allEmailsValid);
  }, [recipients]);

  const receiveRecipientsFromCSV = (newRec: Array<WishRecipient>) => {
    if (newRec.length > 0) {
      setRecipients(newRec);
      wishConfiguration.recipients = newRec;
    }
    setIsModalShown(false);
  };

  return (
    <>
      <Button variant='secondary' onClick={() => setIsModalShown(true)} style={{ marginTop: '1rem' }}>
        <div>
          <ButtonLabel style={{ marginRight: '0.5rem' }}>Upload your contact list</ButtonLabel>
          <UploadIcon />
        </div>
      </Button>
      <HorizontalLine />
      <RecipientWrapper>
        {recipients?.map((recipient, index) => (
          <RecipientRow index={index} key={recipient.id}>
            <FormControl
              display='flex'
              flexDir='column'
              width='100%'
              paddingRight='1rem'
              isInvalid={isEmpty(recipient.name?.trim())}>
              <FormLabel>Name</FormLabel>
              <TextInput
                type='text'
                placeholder='Name of your recipient'
                onChange={e => {
                  const recCopy = [...(recipients ?? [])];
                  recCopy[index].name = e.target.value.trim();
                  setRecipients(recCopy);
                  wishConfiguration.recipients = recCopy;
                }}
                width='100%'
                defaultValue={recipient.name?.trim()}
                maxLength={20}
                style={{ marginTop: '0.5rem' }}
                autoFocus
              />
              {isEmpty(recipient.name?.trim()) && (
                <InputErrorMessage marginTop='0.5rem'>Name is required</InputErrorMessage>
              )}
            </FormControl>
            <FormControl
              display='flex'
              flexDir='column'
              width='100%'
              paddingRight={recipients.length > 1 ? '1rem' : '0px'}
              isInvalid={
                isEmpty(recipient.email) ||
                !isValidEmail(recipient.email ?? '') ||
                isEmailDuplicate(recipient.email ?? '', recipients, index)
              }>
              <FormLabel>Email</FormLabel>
              <TextInput
                type='text'
                placeholder='Email address of your recipient'
                onChange={e => {
                  const recCopy = [...(recipients ?? [])];
                  recCopy[index].email = e.target.value;
                  setRecipients(recCopy);
                  wishConfiguration.recipients = recCopy;
                }}
                width='100%'
                defaultValue={recipient.email}
                maxLength={50}
                style={{ marginTop: '0.5rem' }}
              />
              {isEmpty(recipient.email) && (
                <InputErrorMessage marginTop='0.5rem'>E-mail address is required</InputErrorMessage>
              )}
              {!isEmpty(recipient.email) && !isValidEmail(recipient.email ?? '') && (
                <InputErrorMessage marginTop='0.5rem'>Invalid e-mail address</InputErrorMessage>
              )}
              {!isEmpty(recipient.email) &&
                !!isValidEmail(recipient.email ?? '') &&
                isEmailDuplicate(recipient.email ?? '', recipients, index) && (
                  <InputErrorMessage marginTop='0.5rem'>E-mail address duplicated</InputErrorMessage>
                )}
            </FormControl>
            {recipients.length > 1 && (
              <SmallIconButton
                variant='secondary'
                style={{
                  height: '2.1rem',
                }}
                onClick={() => {
                  const recCopy = [...(recipients ?? [])];
                  recCopy.splice(index, 1);
                  setRecipients(recCopy);
                  wishConfiguration.recipients = recCopy;
                }}>
                <MinusIcon />
              </SmallIconButton>
            )}
          </RecipientRow>
        ))}
      </RecipientWrapper>
      {recipients.length < 10 && (
        <AddButtonWrapper>
          <SmallButton
            variant='secondary'
            onClick={() => {
              const recCopy = [...(recipients ?? [])];
              recCopy.push({ id: uuidv4(), name: '', email: '' });
              setRecipients(recCopy);
              wishConfiguration.recipients = recCopy;
            }}>
            <Label style={{ marginRight: '0.5rem' }}>Add recipient</Label>
            <AddIcon />
          </SmallButton>
        </AddButtonWrapper>
      )}
      {isModalShown && (
        <Modal receiveRecipientsFromCSV={receiveRecipientsFromCSV} onClose={() => setIsModalShown(false)} />
      )}
    </>
  );
};

export default RecipientAddition;
