export enum WizardSteps {
  WIZARD_GREETING_CHOICE = 'WIZARD_GREETING_CHOICE',
  WIZARD_PERSONALIZE_MESSAGE = 'WIZARD_PERSONALIZE_MESSAGE',
  WIZARD_RECIPIENT_ADDITION = 'WIZARD_RECIPIENT_ADDITION',
  WIZARD_WISH_PREVIEW = 'WIZARD_WISH_PREVIEW',
  WIZARD_COMPLETION = 'WIZARD_COMPLETION',
}

export type WizardStepProps = {
  wishConfiguration: WishConfiguration;
  setCanContinue: (canContinue: boolean) => void;
  isSubmitting: boolean;
};

export enum GreetingType {
  HOLIDAY_GIFT = 'HOLIDAY GIFT',
  SHARED_MOMENTS = 'SHARED MOMENTS',
  FESTIVE_JOURNEY = 'FESTIVE JOURNEY',
}

export type WishRecipient = {
  id: string;
  name?: string;
  email?: string;
};

export type WishConfiguration = {
  greetingType?: GreetingType;
  wishHeadline?: string;
  wishMessage?: string;
  recipients?: Array<WishRecipient>;
  sender?: string;
};
