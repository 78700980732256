import { GreetingType } from '../../pages/Sender/CreationWizard/types';

export const DEFAULT_VIDEO = '/videos/main_bg.mp4';
export const DEFAULT_POSTER = '/videos/main_bg.jpg';

export const senderVideoConfiguration = {
  [GreetingType.HOLIDAY_GIFT]: '/videos/sender/HolidayGift_BG.mp4',
  [GreetingType.SHARED_MOMENTS]: '/videos/sender/SharedMoments_BG.mp4',
  [GreetingType.FESTIVE_JOURNEY]: '/videos/sender/FestiveJourney_BG.mp4',
};

export const senderPosterConfiguration = {
  [GreetingType.HOLIDAY_GIFT]: '/videos/sender/HolidayGift_BG.jpg',
  [GreetingType.SHARED_MOMENTS]: '/videos/sender/SharedMoments_BG.jpg',
  [GreetingType.FESTIVE_JOURNEY]: '/videos/sender/FestiveJourney_BG.jpg',
};

export const receiverVideoConfiguration = {
  [GreetingType.HOLIDAY_GIFT]: '/videos/receiver/HolidayGift_BG.mp4',
  [GreetingType.SHARED_MOMENTS]: '/videos/receiver/SharedMoments_BG.mp4',
  [GreetingType.FESTIVE_JOURNEY]: '/videos/receiver/FestiveJourney_BG.mp4',
};

export const receiverPosterConfiguration = {
  [GreetingType.HOLIDAY_GIFT]: '/videos/receiver/HolidayGift_BG.jpg',
  [GreetingType.SHARED_MOMENTS]: '/videos/receiver/SharedMoments_BG.jpg',
  [GreetingType.FESTIVE_JOURNEY]: '/videos/receiver/FestiveJourney_BG.jpg',
};
