import { useEffect, useRef, useState } from 'react';

import { isEmpty } from 'lodash';

import MuteIcon from '../../components/Icons/MuteIcon';
import ReplayIcon from '../../components/Icons/ReplayIcon';
import UnMuteIcon from '../../components/Icons/UnMuteIcon';
import { H1Cursor, H2, H4 } from '../../components/Typography/Typography.styles';
import {
  DEFAULT_VIDEO,
  receiverVideoConfiguration,
  DEFAULT_POSTER,
  receiverPosterConfiguration,
} from '../../layout/MainLayout/utils';
import { MuteIconWrapper, ReceiverWrapper, ReplayIconWrapper } from '../Receiver/Home/Home.styles';
import { GreetingType, WishConfiguration } from '../Sender/CreationWizard/types';

const WishSneak = () => {
  const wishConfiguration = JSON.parse(sessionStorage.getItem('temp_wishconfig') ?? '{}') as WishConfiguration;
  const [playVideo, setPlayVideo] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>(DEFAULT_VIDEO);
  const [posterUrl, setPosterUrl] = useState<string>(DEFAULT_POSTER);

  const [canReplay, setCanReplay] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const containerRef = useRef<any>(null);

  useEffect(() => {
    setVideoUrl(receiverVideoConfiguration[wishConfiguration.greetingType ?? GreetingType.HOLIDAY_GIFT]);
    setPosterUrl(receiverPosterConfiguration[wishConfiguration.greetingType ?? GreetingType.HOLIDAY_GIFT]);
    setPlayVideo(true);
  }, []);

  useEffect(() => {
    if (isEmpty(videoUrl)) {
      return;
    }

    if (videoRef.current) {
      videoRef.current.style.opacity = '0';
      containerRef.current.style.opacity = '0';
    }
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.load();
        videoRef.current.style.opacity = '1';
      }
    }, 300);

    const eventHandler = () => {
      setTimeout(() => {
        containerRef.current.style.opacity = '1';
        setCanReplay(true);
      }, 50);
    };

    videoRef.current?.addEventListener('ended', eventHandler);

    return () => {
      videoRef.current?.removeEventListener('ended', eventHandler);
    };
  }, [videoUrl]);

  return (
    <>
      {playVideo && (
        <>
          <video
            playsInline
            autoPlay
            muted={isMuted}
            loop={false}
            poster={posterUrl}
            ref={videoRef}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transition: 'all 500ms',
              zIndex: 999,
            }}>
            <source src={videoUrl} type='video/mp4' />
          </video>
          {videoUrl && !canReplay && (
            <MuteIconWrapper variant='secondary' onClick={() => setIsMuted(!isMuted)}>
              {isMuted ? <MuteIcon /> : <UnMuteIcon />}
            </MuteIconWrapper>
          )}
          <ReceiverWrapper
            ref={containerRef}
            style={{
              width: '35vw',
            }}>
            <ReplayIconWrapper
              variant='secondary'
              onClick={() => {
                if (videoRef.current) {
                  videoRef.current.pause();
                  videoRef.current.currentTime = 0;
                  videoRef.current.play();
                  containerRef.current.style.opacity = '0';
                  setCanReplay(false);
                }
              }}>
              <ReplayIcon />
            </ReplayIconWrapper>
            {wishConfiguration.wishHeadline && <H1Cursor>—</H1Cursor>}
            {wishConfiguration.wishHeadline && <H2>{wishConfiguration.wishHeadline}</H2>}
            <H4 style={{ marginTop: '2rem' }}>{wishConfiguration.wishMessage}</H4>
            <H4 style={{ marginTop: '2rem' }}>{wishConfiguration.sender}</H4>
          </ReceiverWrapper>
        </>
      )}
    </>
  );
};

export default WishSneak;
